<template>
  <div>
    <h1 class="mb-0">Reset Password</h1>

    <p>
      Enter your email address and we'll send you an email with instructions to
      reset your password.
    </p>
    <error v-if="error" :error="error" />
    <form class="mt-4" @submit.prevent="handleSubmit">
      <div class="form-group">
        <input
          type="email"
          v-model="user.email"
          class="form-control mb-0"
          id="email"
          aria-describedby="emailHelp"
          placeholder="Enter email"
        />
      </div>

      <div class="d-inline-block w-100">
        <button type="submit" class="btn btn-bluish mt-4 w-auto px-4">
          Reset Password
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import axios from "../../../components/axios";
import Error from "../../../components/socialvue/error/Error";
export default {
  name: "RecoverPassword1",
  components: {
    Error,
  },
  data() {
    return {
      user: {
        email: "",
      },
      error: "",
      isClicked: false,
    };
  },
  methods: {
    async handleSubmit() {
      const config = {
        method: "post",
        url: "forgotpassword",
        data: this.user,
      };

      if (this.isClicked == false) {
        this.isClicked = true;
        await axios(config)
          .then((res) => {
            let data = JSON.parse(res.config.data);
            this.$router.push({
              name: "auth1.confirm-mail1",
              params: { email: data.email },
            });
          })
          .catch((err) => {
            console.log(err.response);
            this.error = "Email Not Valid";
          });
      } else if (this.isClicked == true) {
        alert(
          "Password Reset Email already sent. Please check your email or Refresh to submit another request"
        );
      }
    },
  },
};
</script>
